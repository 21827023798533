<template>
  <div class="common-layout">
    <el-container style="height: 100vh">
      <v-sidebar></v-sidebar>
      <el-container>
        <el-header class="header">
          <v-header></v-header>
        </el-header>
        <el-main style="padding-top: 0; padding-bottom: 0; position: relative">
          <div v-if="getLoading" class="loading" v-loading.fullscreen.lock="getLoading"></div>
          <router-view v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
            </keep-alive>
            <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
          </router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useGetters } from '@/utils/mapStore';
export default {
  name: 'index',
  components: {
    VSidebar: defineAsyncComponent(() => import('@/components/common/VSidebar.vue')),
    VHeader: defineAsyncComponent(() => import('@/components/common/VHeader.vue')),
  },
  setup() {
    const getLoading = useGetters('user', ['getLoading']);
    return { getLoading };
  },
};
</script>
<style lang="scss" scoped>
.header {
  height: $header-height;
}
.loading {
  position: absolute;
  width: calc(100% - 40px);
  height: 100%;
  z-index: 999;
}
</style>
